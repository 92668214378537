<template>
  <v-container :style="{ maxWidth: '1318px' }" class="mt-8">
    <v-breadcrumbs :items="breadcrumbs" class="pa-0 ma-0" />
    <div class="font-weight-bold text-h4">{{ this.title }}</div>
    <div class="font-weight-bold text-h6 mt-16">{{ $t('label.fillInTheForm') }}</div>

    <v-divider class="my-5" />
    <v-row>
      <v-col cols="12" sm="3">
        <div class="font-weight-bold">{{ $t('label.personalData') }}</div>
      </v-col>

      <v-col>
        <v-form ref="basicInfo">
          <v-row>
            <v-col cols="12" sm="6" md="4">
              <app-form-field v-model="form.name" :label="$t('label.fullNameAsPerNric')" :placeholder="$t('label.fullName')" :rules="$validation.nameRules()" />
            </v-col>

            <v-col cols="12" sm="6" md="4">
              <app-form-field v-model="form.nric" :label="$t('label.nricNoMalaysianOnly')" mask="######-##-####" placeholder="------ - -- - ----" :rules="$validation.malaysianIdentityNumberRules()" />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="6" md="4">
              <app-form-field
                v-model="form.monthlyIncome"
                item-text="text"
                item-value="uuid"
                :label="$t('label.monthlyIncome')"
                :items="monthlyIncomeDdl"
                type="select"
                :placeholder="$t('label.pleaseSelect')"
                :rules="$validation.requiredRules()"
              />
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <app-form-field v-model="form.email" :label="$t('label.email')" placeholder="eg: abc@gmail.com" :rules="$validation.emailRules()" />
            </v-col>
          </v-row>
        </v-form>

        <v-row>
          <v-col cols="12" sm="6" md="4">
            <v-form ref="mobileNumber" v-on:submit.prevent>
              <app-form-field v-model="form.mobileNumber" prefix="+60" z placeholder="111 ---- ----" :label="$t('label.mobileNumber')" :rules="$validation.mobileNumberRules()" />
            </v-form>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-form ref="tac" v-on:submit.prevent>
              <app-form-field
                v-model="form.tacNumber"
                :label="$t('label.requestTacNumber')"
                :placeholder="$t('label.enterSmsTacCode')"
                :append-button="{
                  title: tacResendCountDown > 0 ? `${$t('action.getCode')} (${this.tacResendCountDown}S)` : $t('action.getCode'),
                  onClick: getTacCode,
                  disabled: tacResendCountDown > 0
                }"
                :rules="$validation.tacRules()"
              />
            </v-form>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-divider class="my-5" />

    <v-form ref="agreementCheckBox">
      <v-row>
        <v-col cols="12" sm="9" offset-sm="3">
          <v-checkbox :rules="[(v) => !!v || $t('validationMessage.youMustAgreeToContinue')]">
            <template v-slot:label>
              <span class="checkbox-label px-4">{{ $t('label.allianceCreditCardCheckbox') }}</span>
            </template>
          </v-checkbox>
        </v-col>
      </v-row>
    </v-form>

    <v-col cols="12" offset-sm="3">
      <div>
        <span>{{ $t('label.clickHereFor') }}{{ ' ' }}</span>
        <template v-for="(document, i) in documents">
          <a :key="document.name" :href="document.link" class="font-italic" target="_blank">{{ document.name }}</a>
          <span :key="`${document.name}-separator`" v-text="getSeparators(i, documents.length)" />
        </template>
      </div>

      <app-button class="my-4" :title="$t('action.submitNow')" type="default" :action="onSubmitClick" />
    </v-col>
  </v-container>
</template>

<script>
import { SHARED_CLOSE_DIALOG, SHARED_LOADING, SHARED_SHOW_DIALOG, SHARED_UNLOADING } from '@/store/shared.module'
import { LOOKUPS_INCOME_RANGES } from '@/store/lookup.module'
import { LOAN_CREDIT_CARD_REQUEST_TAC, LOAN_CREDIT_CARD_APPLICATION_SUBMIT } from '@/store/loan.module'
import { ROUTE_NAME } from '@/constants/route.constants'
import { localeHelper } from '@/utils'

export default {
  name: 'creditCardApplyForm',
  data() {
    return {
      title: '',
      creditCardUuid: '',
      documents: '',
      checkboxLabel: '',
      form: {
        name: '',
        nric: '',
        monthlyIncome: '',
        mobileNumber: '',
        email: '',
        tacNumber: ''
      },
      tacResendCountDown: 0
    }
  },
  mounted() {
    const { title, creditCardUuid, documents } = this.$route.params
    if (!title || !creditCardUuid || !documents) {
      this.$router.back()
      return
    }

    this.title = title
    this.creditCardUuid = creditCardUuid
    this.documents = documents

    this.$store.dispatch(LOOKUPS_INCOME_RANGES)
  },
  methods: {
    onSubmitClick() {
      let basicInfoValidated = this.$refs.basicInfo.validate()
      let mobileNumberValidated = this.$refs.mobileNumber.validate()
      let tacValidated = this.$refs.tac.validate()
      let agreementValidated = this.$refs.agreementCheckBox.validate()

      if (!basicInfoValidated || !mobileNumberValidated || !tacValidated || !agreementValidated) {
        return
      }

      this.$store.dispatch(SHARED_LOADING)

      let data = {
        tacNumber: this.form.tacNumber,
        creditCardUuid: this.creditCardUuid,
        incomeRangeUuid: this.form.monthlyIncome,
        name: this.form.name,
        nric: this.form.nric.replaceAll('-', ''),
        phoneCode: '+60',
        phoneNo: this.form.mobileNumber,
        email: this.form.email
      }

      this.$store.dispatch(LOAN_CREDIT_CARD_APPLICATION_SUBMIT, { data })
    },
    getTacCode() {
      if (!this.$refs.mobileNumber.validate()) {
        return
      }

      let data = {
        phoneCode: '+60',
        phoneNo: this.form.mobileNumber
      }

      this.$store.dispatch(LOAN_CREDIT_CARD_REQUEST_TAC, { data })
    },
    getSeparators(index, length) {
      if (index < length - 2) {
        return ', '
      } else if (index === length - 2) {
        return ' & '
      }
      return ''
    },
    tacCountDownTimer() {
      if (this.tacResendCountDown > 0) {
        setTimeout(() => {
          this.tacResendCountDown -= 1
          this.tacCountDownTimer()
        }, 1000)
      }
    }
  },
  computed: {
    monthlyIncomeDdl() {
      return this.$store.state.lookup.incomeRanges.map((o) => {
        return {
          text: o.ranges,
          uuid: o.uuid
        }
      })
    },
    requestTacComplete() {
      return this.$store.state.loan.loanCreditCardRequestTacResponse.complete
    },
    applicationSubmitComplete() {
      return this.$store.state.loan.loanCreditCardApplicationSubmitResponse.complete
    },
    breadcrumbs() {
      return [{ text: 'Unbox Service' }, { text: 'Cards' }, { text: `Application Form (${this.title})` }]
    }
  },
  watch: {
    requestTacComplete() {
      let response = this.$store.state.loan.loanCreditCardRequestTacResponse

      if (response.complete) {
        this.$store.dispatch(SHARED_UNLOADING)

        const dialogBody = response.success
          ? {
              messageTitle: this.$t('label.tacHasBeenSent'),
              type: 'success'
            }
          : {
              messageTitle: localeHelper.getErrorMessage(`${response.code}`),
              type: 'error'
            }

        this.$store.dispatch(SHARED_SHOW_DIALOG, {
          buttons: [
            {
              type: 'default',
              title: this.$t('action.ok!'),
              action: () => {
                this.$store.dispatch(SHARED_CLOSE_DIALOG)
                if (response.success) {
                  this.tacResendCountDown = 60
                  this.tacCountDownTimer()
                }
              }
            }
          ],
          messageErrorCode: response.code,
          ...dialogBody
        })
      }
    },
    applicationSubmitComplete() {
      let response = this.$store.state.loan.loanCreditCardApplicationSubmitResponse

      if (response.complete) {
        this.$store.dispatch(SHARED_UNLOADING)

        const dialogBody = response.success
          ? {
              messageTitle: this.$t('label.weReceivedYourApplication'),
              messages: [this.$t('label.allianceBankOfficialWillContactYouSoon')],
              type: 'success'
            }
          : {
              messageTitle: this.$t('label.couldntGetYourApplication'),
              messages: [localeHelper.getErrorMessage(`${response.code}`)],
              type: 'error'
            }

        this.$store.dispatch(SHARED_SHOW_DIALOG, {
          buttons: [
            {
              type: 'default',
              title: this.$t('action.ok!'),
              action: () => {
                this.$store.dispatch(SHARED_CLOSE_DIALOG)
                if (response.success) {
                  this.$router.push({
                    name: ROUTE_NAME.HOME
                  })
                }
              }
            }
          ],
          messageErrorCode: response.code,
          ...dialogBody
        })
      }
    }
  }
}
</script>

<style scoped>
::v-deep .v-text-field--placeholder input {
  font-size: 14px;
}

.checkbox-label {
  font-size: 14px;
  color: grey;
}
</style>
